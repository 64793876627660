import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
  connect() {
    console.log('connect')
  }

  submit(e) {
    let form = e.target.closest('form')
    let formData = new FormData(form)
    let request = new XMLHttpRequest();
    let oldContent = form.innerHTML;
    form.innerHTML = 'Saving...'

    // Define what happens on successful data submission
    request.addEventListener('load', (event) => form.innerHTML = oldContent)

    // Define what happens in case of error
    request.addEventListener('error', function (event) {
      alert('Oops! Something went wrong.');
    });

    // Set up our request
    request.open('PATCH', form.action);

    // The data sent is what the user provided in the form
    request.send(formData);
  }
}
