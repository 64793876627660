import { Controller } from "@hotwired/stimulus"
import dragula from 'dragula/dist/dragula.min'

// Connects to data-controller="drag-export-configuration"
export default class extends Controller {
  static targets = ['column']

  connect() {
    let drake = dragula(this.columnTargets)
  }
}
