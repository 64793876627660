import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'portal', 'allow' ];
  
  connect() {
    this.update()
  }

  update() {
    if (this.portalTarget.checked) {
      this.show(this.allowTarget.parentNode)
    } else {
      this.hide(this.allowTarget.parentNode)
    }
  }

  show(el) {
    el.style.display = 'block'
  }
  hide(el) {
    el.style.display = 'none'
  }
}
