import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'body' ]
  static values = { template: String }

  connect() {
  }

  add(event) {
    event.preventDefault()

    var element = document.createElement('tbody');
    element.innerHTML = this.templateValue;

    this.bodyTarget.appendChild(element.firstChild);
  }
}
