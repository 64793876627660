import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'type', 'price', 'share' ];

  connect() {
    this.update()
  }

  update() {
    if (['revenue_share', 'net_revenue_share'].indexOf(this.typeTarget.value) !== -1) {
      this.hide(this.priceTarget)
      this.show(this.shareTarget)
    } else {
      this.hide(this.shareTarget)
      this.show(this.priceTarget)
    }
  }

  show(el) {
    el.style.display = 'block'
  }
  hide(el) {
    el.style.display = 'none'
  }
}
