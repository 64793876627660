import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.scrollTo();
  }

  scrollTo(element) {
    // find target and move it into view
    let target_id = this.data.get('target');
    if (target_id) {
      let el = document.getElementById(target_id);
      if (el) {
        el.scrollIntoView();
      }
    }
  }
}
