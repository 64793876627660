import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'content', 'subCheckbox'];

  initialize() {}

  connect() {
    this.loadIndeterminate();
    this.updateCheckbox();
    this.setNameAttributeForSubCheckboxes();

    if (this.data.get('open') === 'true') {
      this.open();
    }
  }

  open() {
    this.element.classList.add('c-zipcode-filter-item--open');
  }

  toggleShow(e) {
    this.element.classList.toggle('c-zipcode-filter-item--open');
  }

  updateCheckbox() {
    if (this.allTheSame(this.subCheckboxTargets)) {
      this.unsetIndeterminate();
    } else {
      this.setIndeterminate();
    }

    if (this.checkboxTarget.indeterminate == false) {
      if (this.subCheckboxTargets[0].checked) {
        this.check(this.checkboxTarget);
      } else {
        this.uncheck(this.checkboxTarget);
      }
    }
  }

  toggleSelects(e) {
    if (this.subCheckboxTargets.length > 0) {
      let new_state = false;
      if (this.checkboxTarget.checked == true) {
        new_state = true;
      }

      this.subCheckboxTargets.forEach(item => {
        if (new_state == true) {
          this.check(item);
        } else {
          this.uncheck(item);
        }
      });
    }
  }

  selectAll() {
    this.check(this.checkboxTarget);
    this.unsetIndeterminate();
    if (this.subCheckboxTargets.length > 0) {
      this.subCheckboxTargets.forEach(item => {
        this.check(item);
      });
    }
  }

  deselectAll() {
    this.uncheck(this.checkboxTarget);
    this.unsetIndeterminate();
    if (this.subCheckboxTargets.length > 0) {
      this.subCheckboxTargets.forEach(item => {
        this.uncheck(item);
      });
    }
  }

  check(input) {
    input.checked = true;
    input.setAttribute('checked', true);
  }

  uncheck(input) {
    input.checked = false;
    input.removeAttribute('checked');
  }

  loadIndeterminate() {
    if (this.data.has('indeterminate')) {
      if (this.data.get('indeterminate') === 'true') {
        this.setIndeterminate();
      } else {
        this.unsetIndeterminate();
      }
    }
  }

  setIndeterminate() {
    this.data.set('indeterminate', 'true');
    this.checkboxTarget.indeterminate = true;
  }

  unsetIndeterminate() {
    this.data.set('indeterminate', 'false');
    this.checkboxTarget.indeterminate = false;
  }

  toggleSub(e) {
    if (e.target.checked) {
      this.check(e.target);
    } else {
      this.uncheck(e.target);
    }

    this.updateCheckbox();
  }

  allTheSame(arr) {
    return arr.every(v => v.checked === arr[0].checked);
  }

  // if we are in the 'allowed regions' container, we need our data to be posted
  // therefor we change the 'data-name' attribute to the 'name' attribute
  setNameAttributeForSubCheckboxes() {
    if (
      this.element.parentElement.getAttribute('data-zipcode-filter-target') == 'allowed'
    ) {
      this.subCheckboxTargets.forEach(el =>
        el.setAttribute('name', el.getAttribute('data-name'))
      );
    }
  }
}
