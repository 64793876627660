import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  // This drawer controller acts on the hash value in the url

  connect() {
    this.hash = window.location.hash // including #
    this.targetPrefix = this.data.get('target-prefix');
    this.targetUrl = this.data.get('target-url'); // note: we actually make a request to /<target-url>/<id>
    this.id = this.getId();
    this.url = null;
    if (this.id != null && this.targetUrl != null) {
      this.url = this.targetUrl + "/" + this.id
      this.openDrawer();
      this.scrollIntoView();
    }
  }

  getId() {
    let res = null;
    if ( this.targetPrefix != null && this.hash != null ) {
      let splitted = this.hash.split(this.targetPrefix + "-");
      res = splitted[1];
    }
    return res;
  }

  openDrawer() {
    if(this.id != null)  {
      $.get(this.url, function(res) {
        // nothing needed here
      });
    }
  }

  scrollIntoView() {
    let el = document.getElementById(this.targetPrefix + '-' + this.id);
    if ( el != null ) {
      el.scrollIntoView();
    }
  }
}
