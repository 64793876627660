import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="export-configuration"
export default class extends Controller {
  static targets = ['form']

  connect() {
  }

  setParameters(e) {
    if(e.target.href.indexOf('?') === -1) {
      e.preventDefault()

      let form_data = new FormData(this.formTarget)
      form_data.delete('ids')
      form_data.delete('authenticity_token')
      form_data.delete('send_export_to')
      let parameters = new URLSearchParams(form_data)
      e.target.href += '?' + parameters.toString()
      e.target.click()
      this.disableForm(e)
    }
  }

  disableForm(e) {
    this.formTarget.querySelectorAll('input[type="checkbox"]').forEach((elem) => elem.disabled = true)
  }

  enableForm(e) {
    this.formTarget.querySelectorAll('input[type="checkbox"]').forEach((elem) => elem.disabled = false)
  }

  activate(e) {
    let form_data = new FormData(this.formTarget)
    let button_form = e.target.closest('form')
    button_form.innerHTML += '<input type="hidden" name="ids" value="' + form_data.get('ids') + '" />'
    button_form.submit()
  }
}
