import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = [ 'trigger', 'toggle' ]
  static values = { className: String, preventDefault: Boolean }

  connect() {
    this.triggerTargets.forEach(elem => elem.addEventListener('click', (e) => {
      if(elem.href === window.location.href || elem.href === '') {
        e.preventDefault()
      }
      this.toggleTargets.forEach(element => element.classList.toggle(this.classNameValue))
    }))
  }
}
