import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'paymentElement',
    'stripeErrors',
    'cardholderName',
    'paymentIntent',
    'paymentMethod',
    'form',
    'methodSelection',
    'ibanWrapper',
    'cardWrapper',
    'accountholderName',
    'email'
  ];

  connect() {
    var key = this.data.get('stripe-publishable-key');

    this.style = {
      base: {
        color: '#212529',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#6c757d'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.stripe = Stripe(key);
    this.elements = this.stripe.elements({
      locale: 'en'
    });
  }

  save(e) {
    var errors = this.stripeErrorsTarget;
    var cardholderName = this.cardholderNameTarget;
    var clientSecret = this.data.get('stripe-client-secret');

    e.preventDefault();
    e.target.disabled = true;

    var promise = undefined;
    if (!this.hasIbanWrapperTarget) {
      /* manual payment */
      if (this.methodSelectionTarget.value == 'ideal') {
        promise = this.stripe.confirmIdealPayment(clientSecret, {
          payment_method: {
            ideal: this.paymentElement
          },
          return_url: this.data.get('ideal-return-url')
        });
      } else if (this.methodSelectionTarget.value == 'card') {
        promise = this.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: this.paymentElement,
            billing_details: { name: cardholderName.value }
          }
        });
      }
    } else {
      /* pre configure payment method, used for invoicng at end of period */
      if(this.methodSelectionTarget.value == 'iban') {
         promise = this.stripe.confirmSepaDebitSetup(clientSecret, {
           payment_method: {
             sepa_debit: this.paymentElement,
             billing_details: {
               name: this.accountholderNameTarget.value,
               email: this.emailTarget.value
             }
           }
         });
      } else if(this.methodSelectionTarget.value == 'auBankAccount') {
         promise = this.stripe.confirmAuBecsDebitSetup(clientSecret, {
           payment_method: {
             au_becs_debit: this.paymentElement,
             billing_details: {
               name: this.accountholderNameTarget.value,
               email: this.emailTarget.value
             }
           }
         });
      } else if(this.methodSelectionTarget.value == 'card') {
        promise = this.stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: this.paymentElement,
            billing_details: { name: cardholderName.value }
          }
        });
      }
    }

    promise.then(result => {
      e.target.disabled = false;
      if (
        result.error ||
        (!this.hasIbanWrapperTarget &&
          result.paymentIntent.status !== 'succeeded')
      ) {
        errors.innerHTML = result.error.message;
        errors.style.display = 'block';
      } else {
        if (!this.hasIbanWrapperTarget) {
          this.paymentIntentTarget.value = result.paymentIntent.id;
        } else {
          this.paymentMethodTarget.value = result.setupIntent.payment_method;
        }
        this.formTarget.submit();
      }
    });
  }

  change(e) {
    cardButton.prop('disabled', false);
    if (e.error) {
      errors.innerHTML = e.error.message;
      errors.style.display = 'block';
    } else {
      errors.innerHTML = '';
      errors.style.display = 'none';
    }
  }

  /* user chosen payment method for manual payments */
  changeMethod(e) {
    if (
      typeof this.paymentElement != 'undefined' &&
      this.paymentElement._destroyed != true
    ) {
      this.paymentElement.destroy();
      this.cardWrapperTarget.style.display = 'none';
      if (this.hasIbanWrapperTarget) {
        this.ibanWrapperTarget.style.display = 'none';
      }
    }

    if (this.methodSelectionTarget.value == 'ideal') {
      this.paymentElement = this.elements.create('idealBank', {
        style: this.style
      });
      this.paymentElement.mount(this.paymentElementTarget);
    } else if (this.methodSelectionTarget.value == 'card') {
      this.paymentElement = this.elements.create('card', { style: this.style });
      this.paymentElement.mount(this.paymentElementTarget);
      this.cardWrapperTarget.style.display = 'block';
    } else if (this.methodSelectionTarget.value == 'iban') {
      this.paymentElement = this.elements.create('iban', {
        supportedCountries: ['SEPA'],
        style: this.style,
        placeholderCountry: this.data.get('placeholder-country')
      });
      this.paymentElement.mount(this.paymentElementTarget);
      this.ibanWrapperTarget.style.display = 'block';
    } else if (this.methodSelectionTarget.value == 'auBankAccount') {
      this.paymentElement = this.elements.create('auBankAccount', {
        style: this.style,
      });
      this.paymentElement.mount(this.paymentElementTarget);
      this.ibanWrapperTarget.style.display = 'block';
    }
  }
}
