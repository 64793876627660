import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'center',
    'radius',
    'results',
    'allowed',
    'search',
    'editButton',
    'searchContainer',
    'close'
  ];

  connect() {
    if (this.hasSelectedRegions()) {
      this.hideSearch(false);
    } else {
      this.showSearch(false);
    }
  }

  hasSelectedRegions() {
    return (
      this.allowedTarget.querySelectorAll(
        '[data-controller="zipcode-filter-item"]'
      ).length > 0
    );
  }

  showSearch(e) {
    if (e != false) {
      e.preventDefault();
    }
    this.searchContainerTarget.classList.remove('d-none');
    this.editButtonTarget.classList.add('d-none');
  }

  hideSearch(e) {
    if (e != false) {
      e.preventDefault();
    }
    this.searchContainerTarget.classList.add('d-none');
    this.editButtonTarget.classList.remove('d-none');
  }

  search(e) {
    e.preventDefault();

    let center = this.centerTarget.value;
    let radius = this.radiusTarget.value;

    this.searchTarget.setAttribute('disabled', 'disabled');
    this.searchTarget
      .querySelector('.spinner-border')
      .classList.remove('d-none');

    /* TODO rewrite with fetch and test in IE11 */
    jQuery.ajax({
      url: this.data.get('url'),
      method: 'post',
      data: {
        center: center,
        radius: radius,
        index: this.data.get('index')
      },
      success: data => {
        this.resultsTarget.innerHTML = data.zipcode_filter_items_html;
        this.searchTarget.removeAttribute('disabled');
        this.searchTarget
          .querySelector('.spinner-border')
          .classList.add('d-none');
      }
    });
  }

  searchWithEnter(e) {
    if (e.keyCode == 13) {
      this.search(e);
    }
  }

  selectAll(e) {
    e.preventDefault();

    let items = this.resultsTarget.querySelectorAll(
      '[data-controller="zipcode-filter-item"]'
    );

    items.forEach(item => {
      let zipcode_filter_item = this.application.getControllerForElementAndIdentifier(
        item,
        'zipcode-filter-item'
      );
      zipcode_filter_item.selectAll();
    });
  }

  deselectAll(e) {
    e.preventDefault();

    let items = this.resultsTarget.querySelectorAll(
      '[data-controller="zipcode-filter-item"]'
    );

    items.forEach(item => {
      let zipcode_filter_item = this.application.getControllerForElementAndIdentifier(
        item,
        'zipcode-filter-item'
      );
      zipcode_filter_item.deselectAll();
    });
  }

  moveSelectedToAllowed(e) {
    e.preventDefault();

    let selected_sub_regions = this.resultsTarget.querySelectorAll(
      '[data-name*=sub_region_ids]:checked'
    );

    let selected_ids = [];
    selected_sub_regions.forEach(sub_region => {
      selected_ids.push(sub_region.value);
    });

    let allowed_sub_regions = this.allowedTarget.querySelectorAll(
      '[data-name*=sub_region_ids]:checked'
    );
    let allowed_ids = [];
    allowed_sub_regions.forEach(sub_region => {
      allowed_ids.push(sub_region.value);
    });

    if (selected_ids.length > 0) {
      /* TODO rewrite with fetch and test in IE11 */
      jQuery.ajax({
        url: this.data.get('url'),
        method: 'post',
        data: {
          new_ids: selected_ids,
          current_ids: allowed_ids,
          index: this.data.get('index')
        },
        success: data => {
          this.allowedTarget.innerHTML = data.zipcode_filter_items_html;
        }
      });
    }
  }
}
