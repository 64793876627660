import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'field', 'value' ]

  connect() {
    this.update()
  }

  update() {
    this.valueTargets.forEach((elem) => {
      var visibleElement = elem;
      if(elem.closest('div').className == 'q8form__checkboxes-wrapper') {
        visibleElement = elem.closest('div')
      }
      if(!elem.dataset.originalName) {
        elem.dataset.originalName = elem.name
      }
      if(`defaults[${this.fieldTarget.value}]` == elem.dataset.originalName ||
          `defaults[${this.fieldTarget.value}][]` == elem.dataset.originalName) {
        visibleElement.style.display = 'block'
        elem.name = elem.dataset.originalName
      } else {
        visibleElement.style.display = 'none'
        elem.name = undefined /* disable post of this field */
      }
    })
  }

  delete(event) {
    event.preventDefault()

    this.element.outerHTML = `<input type="hidden" name="defaults[${this.fieldTarget.value}]" value="" />`
  }
}
