import Pickr from '@simonwep/pickr';

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // example: https://jsfiddle.net/Simonwep/wL1zyqcd/
  connect() {
    const pickr = new Pickr({
      el: this.element,
      useAsButton: true,
      default: this.element.value || '#15adec',
      theme: 'nano',

      swatches: [
        'rgba(244, 67, 54, 1)',
        'rgba(233, 30, 99, 1)',
        'rgba(156, 39, 176, 1)',
        'rgba(103, 58, 183, 1',
        'rgba(63, 81, 181, 1)',
        'rgba(33, 150, 243, 1)',
        'rgba(3, 169, 244, 1)',
        'rgba(0, 188, 212, 1)',
        'rgba(0, 150, 136, 1)',
        'rgba(76, 175, 80, 1)',
        'rgba(139, 195, 74, 1)',
        'rgba(205, 220, 57, 1)',
        'rgba(255, 235, 59, 1)',
        'rgba(255, 193, 7, 1)',
      ],

      components: {
        preview: true,
        opacity: false,
        hue: true,

        interaction: {
          hex: false,
          rgba: false,
          hsva: false,
          input: true,
          save: true,
        },
      },
    })
      .on('init', (pickr) => {
        this.element.value = pickr.getSelectedColor().toHEXA().toString(0);
        this.setStyle();
      })
      .on('save', (color) => {
        this.element.value = color.toHEXA().toString(0);
        this.setStyle();
        pickr.hide();
      });
  }

  setStyle() {
    this.element.style.borderColor = this.element.value;
    this.element.style.boxShadow = 'inset 0 0 0 3px' + this.element.value;
  }
}
