import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'countryManagerSelector' ];
  changeAll(e) {
    this.countryManagerSelectorTargets.forEach((managerSelector, index) => {
      managerSelector.value = e.target.value;
      managerSelector.dispatchEvent(new Event('change'));
    });
  }
  changeCountry(e) {
    let selector = e.target.dataset.argument;
    this.targets.findAll(selector).forEach((managerSelector, index) => {
      managerSelector.value = e.target.value;
    });
  }
}
